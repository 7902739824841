@keyframes text-change {
  from {
    color: #fff;
  }
  to {
    color: red;
  }
}

@keyframes blink-border {
  from {
    border-color: #fff;
  }
  50% {
    border-color: red;
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes bg-colors {
  0% {
    background-color: #555;
  }
  10% {
    background-color: red;
  }
  20% {
    background-color: orange;
  }
  30% {
    background-color: yellow;
  }
  40% {
    background-color: green;
  }
  50% {
    background-color: blue;
  }
  60% {
    background-color: indigo;
  }
  70% {
    background-color: violet;
  }
  80% {
    background-color: #000;
  }
  90% {
    background-color: #ccc;
  }
  100% {
    background-color: blanchedalmond;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.15em;
  padding-top: 50px;
  color: #fff;
  background-color: rgb(30, 31, 31);

  /* animation: bg-colors 9s linear infinite; */
}

a {
  color: rgb(66, 105, 112);
}

a:hover,
.social-media:hover {
  color: rgb(83, 144, 155);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  /* height: 100%;
  width: 100%; */
  position: absolute;
  z-index: -1;
}

.background .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(30, 31, 31);
  opacity: 0.2;
  z-index: -1;
  margin: 0 auto;
}

.background img {
  max-width: 800px;
  margin: 0 auto;
}

.social-media {
  font-size: 2em;
  display: block;
  /* color: #fff; */
}

.shine {
  background: linear-gradient(to right, #fff 20%, red 40%, red 60%, #fff 80%);
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

.profile-pic {
  max-width: 200px;
  max-height: 175px;
  border: 3px solid #fff;
  /* animation: rotate 10s linear infinite; */
}

.typewriter {
  display: inline-block;
}

.typewriter * {
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  overflow: hidden;
  border-right: 0.15em solid orange;
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
}

.project {
  padding: 20px;
  border-radius: 4px;
}

.video-container {
  padding: 20px;
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;
  /* border-bottom: 3px groove #ccc; */
}

/* @media (max-width: 576px) {
  .social-media {
    font-size: 3em;
  }
} */

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 60px;
  overflow: hidden;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.highlight {
  color: orange;
}

@media (max-width: 576px) {
  .skills {
    font-size: 0.8em;
  }

  .f-value {
    font-size: 1em;
  }
}

.contact {
  border: 1px solid #555;
  border-radius: 4px;
  padding: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #000;
}

.f-label {
  font-size: 0.7em;
  color: orange;
}

.f-value {
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 5px;
}

.instrument-description {
  padding: 10px;
}

.f-section {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.high-low {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.progress-label {
  position: absolute;
  bottom: -35px;
}

.maze-container {
  width: 90%;
  margin: 0 auto;
  background-color: #fefefe;
}

.maze-row {
  display: flex;
  flex-direction: row;
}

.maze-col-1 {
  background-color: #fefefe;
}

.maze-col-0 {
  background-color: #fefefe;
}

.maze-col-0,
.maze-col-1 {
  border-width: 1px;
  border-color: #000;
  border-style: solid;
}

.details li {
  margin: 20px 0;
}
